<template>
  <div class="tabs-wrapper">
    <ul :class="{ 'only-one-active-tab': data.length === 1 }">
      <li
        v-for="(item, index) in data"
        :key="index"
        :class="{ active: activeTab === index && !isAccessInfoItem(item) }"
        @click="setActiveTab(item, index)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EntityPageTabs',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    isAccessInfoItem(item) {
      return item === 'access info';
    },
    setActiveTab(item, index) {
      if (!this.isAccessInfoItem(item)) {
        this.activeTab = index;
      }
      this.$emit('active-tab', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-wrapper {
  display: flex;
  box-shadow: 0 2px 4px hsla(0, 0%, 86.3%, 0.5);

  ul {
    display: flex;
    align-items: center;
    min-height: 80px;
    margin: 0 auto;
    padding-left: 0;
    overflow-x: auto;

    @media (min-width: 768px) {
      min-height: 90px;
    }

    &.only-one-active-tab {
      li {
        cursor: default;
      }
    }

    li {
      min-width: 145px;
      list-style: none;
      cursor: pointer;
      color: #afafaf;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2.86px;
      text-align: center;
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid transparent;
      margin: 5px;
      padding: 8px 0;
      transition: all 0.3s linear;

      &:hover {
        border-color: #353535;
        color: #353535;
      }

      &.active {
        color: #353535;
        border-color: #353535;
      }

      @media (min-width: 768px) {
        margin: 10px 40px;
        font-size: 20px;
      }

      @media (min-width: 992px) {
        margin: 10px 60px;
      }
    }
  }
}
</style>
